import { useEffect } from 'react';
import Head from 'next/head';
import { NextSeo } from 'next-seo';

//helpers
import { sleep } from '@/helpers/sleep';

//screen
import { DefaultErrorSection } from '/screens/';

const handleGTM = async () => {
  await sleep(300);

  if (typeof window === 'undefined') {
    return null;
  }

  const currentPath = window.location.pathname;

  // trigger GA4+GTM event
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push({ event: 'error_404', url: currentPath });
  }
  gtag('js', new Date());
};

const Custom404 = () => {
  useEffect(() => {
    handleGTM();
  }, []);

  return (
    <>
      <Head>
        <meta name="keywords" content="404, error page, loadsmart 404" />
      </Head>
      <NextSeo
        nofollow={process.env.NOINDEX_FLAG}
        noindex={process.env.NOINDEX_FLAG}
        title="Not Found"
        description="Get instant freight quotes and book your full truckload (FTL) shipments in seconds. Manage your shipping needs and track your load in real-time."
        canonical="https://loadsmart.com/404/"
        openGraph={{
          url: 'https://loadsmart.com/404/',
        }}
      />

      <DefaultErrorSection />
    </>
  );
};

export default Custom404;
